<template>
    <div class="container-fluid content-container pl-5 pr-5">


        <div class="row">
            <div class="col-12 bg-light shadow p-3  text-left">
                <p class="heading">Order detail</p>
                <hr/>
                <div class="row">
                    <div class="col-12 " :class="{'opaque':loading}">
                        <div class="text-right">
                            <router-link :to="{name:'orders'}" class="btn btn-outline-info ">View Orders</router-link>
                        </div>


                        <detail :order="order" v-on:sales_man_assigned="load_order" v-on:approved="load_order" @payment_done="load_order"
                                v-on:assigned="load_order" v-on:rejected="load_order"
                                v-on:warehouse_assigned="load_order"></detail>
                    </div>

                </div>


            </div>
        </div>
    </div>
</template>
<script>
    import Loader from '@/loader';
    import Codes from '@/order_codes';
    import {Paths} from '@/paths';
    import Detail from './salesman/OrderDetail.vue';

    export default{
        extends: Loader,
        components: {Detail},
        props: ['order_id'],
        data(){
            return {

                order: null,


            }
        },
        methods: {
            load_order(){
                let app = this;
                this.startLoading();
                this.axios.post(Paths.order.get, {order_id: app.order_id}).then((response) => {
                    app.order = response.data;
                }).finally(() => {
                    app.endLoading()
                });
            },
//            salesManAssigned(data){
////                this.load_order;
//            },
//            warehouseAssigned(data){
//                this.order.warehouse_staff = data;
//                this.order.status = 6;
//            },
//            rejected(){
//                this.order.status = Codes.forRejected();
//                this.refresh_date = !this.refresh_date;
//            },
//            approved(){
//                this.order.status = Codes.forApproved();
//                this.refresh_date = !this.refresh_date;
//            }, assigned(data){
//                this.order.status = Codes.forAssigned();
//                this.order.deliveries = data;
//                this.refresh_date = !this.refresh_date;
//            },
//            currentData(order){
//                this.order = order;
//            },

            readCode(code){
                return Codes.readCode(code);
            }, countOrders(){
                let app = this;

                if (this.type == 0) {
                    return app.total_orders = app.orders.length;
                }
                app.total_orders = 0;
                for (let i = 0; i < this.orders.length; i++) {
                    if (app.orders[i].status == this.type) {
                        app.total_orders++;
                    }
                }
            },
            download_pdf(index){
                let app = this;

                let order = app.orders[index];
                let file_name = order.customer.first_name + "_" + order.id + '_' + order.created_at;

                this.axios({

                    url: app.pdf_path + "?id=" + order.id,

                    method: 'GET',

                    responseType: 'blob',

                }).then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));

                    var fileLink = document.createElement('a');


                    fileLink.href = fileURL;

                    fileLink.setAttribute('download', file_name + '.pdf');

                    document.body.appendChild(fileLink);


                    fileLink.click();

                });
            }
        },
        watch: {
//            type(){
//                this.countOrders();
//            },
//            customer_id(){
//                this.countOrders();
//            },
        },
        computed: {},

        mounted(){
            this.load_order();
        }
    }
</script>